<script setup>

</script>

<template>
  <v-card>

    <v-card-title></v-card-title>

    <router-link to="/inspectionpoints?case=658aa8898db3c04600000142">{{ $t("Inspection Points") }}</router-link>
  </v-card>
</template>

<style scoped>

</style>
