export function convertJsonArraysToJs(newObj) {
    for (const key in newObj) {
        if (key in newObj) {
            try {
                const parsedValue = JSON.parse(newObj[key]);
                if (Array.isArray(parsedValue)) {
                    newObj[key] = parsedValue;
                }
            } catch (error) {
                //console.log(error);
                // Wenn das Parsen fehlschlägt, lassen Sie den Wert unverändert
            }
        }
    }
    return newObj;
}

export function reformatFloats(obj) {
    for (let key in obj) {
        if (typeof obj[key] === 'object') {
            reformatFloats(obj[key]);
        } else if (typeof obj[key] === 'string') {
            obj[key] = obj[key].replace(',', '.');
        }
    }
}

export function floatsToLocal(obj) {
    for (let key in obj) {
        if (typeof obj[key] === 'object') {
            floatsToLocal(obj[key]);
        } else if (typeof obj[key] === 'string' && isFloat(obj[key])) {
            // Komma durch Punkt ersetzen um parseFloat in eine gültige Zahl umzuwandeln
            let num = parseFloat(obj[key].replace(',', '.'));
            // Überprüfen ob Umwandlung erfolgreich war (nicht NaN) und die Zahl ist endlich
            if (!isNaN(num) && isFinite(num)) {
                console.log("change point to comma");
                // toLocaleString auf eine Zahl anwenden
                obj[key] = num.toLocaleString('de-DE', { useGrouping: false });
                console.log(obj[key]);
            }
        }
    }
}

export function isFloat(val) {
    const floatRegex = /^-?\d+(\.\d+)?$/;
    if (!floatRegex.test(val.replace(',', '.')))
        return false;

    return !isNaN(parseFloat(val));
}
