<template>
  <div class="inspection-point-view">
    <!-- Dropdown-Bereich -->
    <div class="dropdowns">

      <!-- Workpiece Dropdown -->
      <select ref="workpieceSelect" class="dropdown" @change="handleWorkpieceDropdownChange">
        <option value="" disabled selected>{{ $t("Please Choose") }}</option>
        <option v-for="item in workpieces" :key="item._id" :value="item._id">{{ item.title }}</option>
      </select>

      <div class="inspection-selection">
        <!-- Linker Pfeil-Button -->
        <button class="arrow-button arrow-button-left" @click="selectPreviousInspectionPoint">&lt;</button>
        <!-- InspectionPoints Dropdown -->
        <select class="inspection-select" @change="handleInspectionPointDropdownChange" ref="inspectionPointSelect">
          <option v-for="item in inspectionPoints" :key="item._id" :value="item._id">{{ item.title }}</option>
        </select>

        <!-- Rechter Pfeil-Button -->
        <button class="arrow-button" @click="selectNextInspectionPoint">&gt;</button>
      </div>
    </div>

    <!-- Hauptbereich -->
    <div class="main-area">
      <!-- Linker Bereich -->
      <div class="area left">
        <table v-if="selectedWorkpiece && Object.keys(selectedWorkpiece).length > 0" class="info-table">
          <tr class="highlighted-row">
            <td>{{ $t("Name of workpiece") }}</td>
            <td>{{ selectedWorkpiece ? selectedWorkpiece.title : "-" }}</td>
          </tr>
          <tr class="highlighted-row">
            <td>{{ $t("Id of workpiece") }}</td>
            <td>{{ selectedWorkpiece ? selectedWorkpiece.number : "-" }}</td>
          </tr>
          <tr class="highlighted-row">
            <td>{{ $t("Inspection Point") }}</td>
            <td>{{ selectedInspectionPoint ? selectedInspectionPoint.title : "-" }}</td>
          </tr>
          <tr class="highlighted-row">
            <td>{{ $t("Id of inspection Point") }}</td>
            <td>{{ selectedInspectionPoint ? selectedInspectionPoint.number : "-" }}</td>
          </tr>
          <tr class="highlight-edit">
            <td>{{ $t("Choose inspection mode") }}</td>
            <td>
              <select :class="{'text-input': true}" v-model="measurement.inspectionMode">
                <option v-for="mode in actualCase.specs.inspectionModeTypes" :key="mode" :value="mode">{{ mode }}</option>
              </select>
            </td>
          </tr>
          <tr class="highlight-edit">
            <td>{{ $t("Choose inspection tool") }}</td>
            <td>
              <select :class="{'text-input': true}" v-model="measurement.inspectionTool">
                <option v-for="mode in actualCase.specs.inspectionToolTypes" :key="mode" :value="mode">{{ mode }}</option>
              </select>
            </td>
          </tr>
          <!-- Maße -->
          <tr class="highlighted-row highlight-edit">
            <td>{{ $t("Enter actual dimension") }}</td>
            <td>
              <input
                  type="text"
                  v-model="measurement.actualDimension"
                  @input="handleInput('actualDimension', $event )"
                  :class="{ 'text-input': true, 'error-input': !validateFloat(measurement.actualDimension) }"
                  :disabled="measurement.inspectionMode !== 'Messen'"
              />
            </td>
          </tr>
          <!-- Select result gauge -->
          <tr class="highlighted-row highlight-edit">
            <td>{{ $t("Select result gauge") }}</td>
            <td>
              <select
                  :class="{'text-input': true}"
                  v-model="measurement.resultGauge"
                  :disabled="measurement.inspectionMode !== 'Lehren'"
              >
                <option v-for="mode in actualCase.specs.resultGaugeTypes" :key="mode" :value="mode">{{ mode }}</option>
              </select>
            </td>
          </tr>

          <!-- Enter nominal dimension -->
          <tr class="value-predefined">
            <td>{{ $t("Enter nominal dimension") }}</td>
            <td>
              <input
                  type="text"
                  v-model="measurement.nominalDimension"
                  @input="handleInput('nominalDimension', $event)"
                  :class="{ 'text-input': true, 'error-input': !validateFloat(measurement.nominalDimension) }"
              />
            </td>
          </tr>

          <!-- Enter maximum dimension -->
          <tr class="value-predefined">
            <td>{{ $t("Enter maximum dimension") }}</td>
            <td>
              <input
                  type="text"
                  v-model="measurement.maximumDimension"
                  @input="handleInput('maximumDimension', $event)"
                  :class="{ 'text-input': true, 'error-input': !validateFloat(measurement.maximumDimension) }"
              />
            </td>
          </tr>

          <!-- Enter minimum dimension -->
          <tr class="value-predefined">
            <td>{{ $t("Enter minimum dimension") }}</td>
            <td>
              <input
                  type="text"
                  v-model="measurement.minimumDimension"
                  @input="handleInput('minimumDimension', $event)"
                  :class="{ 'text-input': true, 'error-input': !validateFloat(measurement.minimumDimension) }"
              />
            </td>
          </tr>

          <!-- Enter upper tolerance -->
          <tr class="value-predefined">
            <td>{{ $t("Enter upper tolerance") }}</td>
            <td>
              <input
                  type="text"
                  v-model="measurement.upperTolerance"
                  @input="handleInput('upperTolerance', $event)"
                  :class="{ 'text-input': true, 'error-input': !validateFloat(measurement.upperTolerance) }"
              />
            </td>
          </tr>

          <!-- Enter lower tolerance -->
          <tr class="value-predefined">
            <td>{{ $t("Enter lower tolerance") }}</td>
            <td>
              <input
                  type="text"
                  v-model="measurement.lowerTolerance"
                  @input="handleInput('lowerTolerance', $event)"
                  :class="{ 'text-input': true, 'error-input': !validateFloat(measurement.lowerTolerance) }"
              />
            </td>
          </tr>

          <!-- Enter tolerance value -->
          <tr class="value-predefined">
            <td>{{ $t("Enter tolerance value") }}</td>
            <td>
              <input
                  type="text"
                  v-model="measurement.toleranceValue"
                  @input="handleInput('toleranceValue', $event)"
                  :class="{ 'text-input': true, 'error-input': !validateFloat(measurement.toleranceValue) }"
              />
            </td>
          </tr>

          <!-- Enter permissible measurement error -->
          <tr class="highlight-edit">
            <td>{{ $t("Enter permissible measurement error") }}</td>
            <td>
              <input
                  type="text"
                  v-model="measurement.permissibleMeasurementError"
                  @input="handleInput('permissibleMeasurementError', $event)"
                  :class="{ 'text-input': true, 'error-input': !validateFloat(measurement.permissibleMeasurementError) }"
              />
            </td>
          </tr>

          <tr class="highlighted-row highlight-edit">
            <td>{{ $t("Inspection point assessment") }}</td>
            <td>
              <select :class="{'text-input': true}" v-model="measurement.inspectionPointAssessment">
                <option v-for="mode in actualCase.specs.inspectionPointAssessmentTypes" :key="mode" :value="mode">{{ mode }}</option>
              </select>
            </td>
          </tr>

          <tr class="highlighted-row highlight-edit">
            <td>{{ $t("Select rework") }}</td>
            <td>
              <select :class="{'text-input': true}" v-model="measurement.rework">
                <option v-for="mode in actualCase.specs.reworkTypes" :key="mode" :value="mode">{{ mode }}</option>
              </select>
            </td>
          </tr>

        </table>
      </div>
      <!-- Rechter Bereich -->
      <div class="area right">
        <div class="right-content top">
          <v-col>
            <v-row>
              <h4>{{ $t("Workpiece Drawing") }}</h4>
            </v-row>
            <v-row>
              <div class="image-container">
                <v-img :src="drawingImageUrl" @click="openDrawing" class="image-full">
                  <div class="marker-rectangle"></div>
                  <!-- Schleife durch alle Inspection Points -->
                    <!-- Hier setzen Sie die Koordinaten des unsichtbaren Rechtecks aus dem marker-Feld -->
                    <div
                        v-for="inspectionPoint in inspectionPoints"
                        @click.stop="handleInspectionPointClick(inspectionPoint)"
                        :key="inspectionPoint._id"
                        class="invisible-rectangle"
                        :style="{
                    left: inspectionPoint.marker ? JSON.parse(inspectionPoint.marker)[0] + '%' : '0%',
                    top: inspectionPoint.marker ? JSON.parse(inspectionPoint.marker)[1] + '%' : '0%',
                    width: inspectionPoint.marker ? JSON.parse(inspectionPoint.marker)[2] + '%' : '0%',
                    height: inspectionPoint.marker ? JSON.parse(inspectionPoint.marker)[3] + '%' : '0%',
                    display: inspectionPoint.marker ? 'block' : 'none'
                  }"
                    ></div>
                </v-img>

              </div>
            </v-row>
          </v-col>
        </div>
        <div class="right-content bottom">
          <v-col>
            <v-row>
              <h4>{{ $t("Test Proposal") }}</h4>
            </v-row>
            <v-row>
              <div class="image-container">
                <v-img :src="suggestionImageUrl" class="image-full"></v-img>
              </div>
            </v-row>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue';
import APIServiceRestDB from "@/services/APIServiceRestDB";
import { useStore } from 'vuex'; // Importieren Sie den Store
import { convertJsonArraysToJs, reformatFloats, floatsToLocal } from '@/tools/utils'

// Reaktive Referenzen für Dropdown-Arrays
const store = useStore(); // Verwenden Sie den Store
const actualCase = ref({
  specs: {
    inspectionModeTypes: [],
    inspectionToolTypes: []
  },
  parameters: {}
    });
const workpieces = ref([]);
const workpieceSelect = ref(null);
const inspectionPoints= ref([]);
const inspectionPointSelect = ref(null);
const title = ref("");
const selectedWorkpiece = ref({});
const selectedInspectionPoint = ref({});
const assetsUrl = ref(process.env.VUE_APP_ASSETS_URL);
const drawingImageUrl = ref('');
const suggestionImageUrl = ref('');
const measurement = ref({
  user: "",
  caseId: "",
  inspectionPointSpecId: "",
  inspectionMode: "Messen",
  inspectionTool: "Messschieber",
  actualDimension: null,
  actualDimension_old: null,
  resultGauge: "Gut",
  resultGauge_old: null,
  nominalDimension: null,
  nominalDimension_old: null,
  maximumDimension: null,
  maximumDimension_old: null,
  minimumDimension: null,
  minimumDimension_old: null,
  upperTolerance: null,
  upperTolerance_old: null,
  lowerTolerance: null,
  lowerTolerance_old: null,
  toleranceValue: null,
  toleranceValue_old: null,
  permissibleMeasurementError: null,
  permissibleMeasurementError_old: null,
  inspectionPointAssessment: "Gut",
  rework: "Nicht erforderlich"
});

// Funktion zum Festlegen der Standardwerte für measurement
function setDefaultMeasurementValues() {
  measurement.value.inspectionPointSpecId = selectedInspectionPoint.value._id;
  measurement.value.user = actualCase.value.parameters.owner;
  measurement.value.caseId = actualCase.value.parameters._id;
  measurement.value.inspectionMode = "Messen";
  measurement.value.inspectionTool = "Messschieber";
  measurement.value.actualDimension = 0;
  measurement.value.actualDimension_old = 0;
  measurement.value.resultGauge = "Gut";
  measurement.value.resultGauge_old = 0;
  measurement.value.nominalDimension = selectedInspectionPoint.value.nominalDimension;
  measurement.value.nominalDimension_old = 0;
  measurement.value.maximumDimension = selectedInspectionPoint.value.maximumDimension;
  measurement.value.maximumDimension_old = 0;
  measurement.value.minimumDimension = selectedInspectionPoint.value.minimumDimension;
  measurement.value.minimumDimension_old = 0;
  measurement.value.upperTolerance = selectedInspectionPoint.value.upperTolerance;
  measurement.value.upperTolerance_old = 0;
  measurement.value.lowerTolerance = selectedInspectionPoint.value.lowerTolerance;
  measurement.value.lowerTolerance_old = 0;
  measurement.value.toleranceValue = selectedInspectionPoint.value.toleranceValue;
  measurement.value.toleranceValue_old = 0;
  measurement.value.permissibleMeasurementError = 0;
  measurement.value.permissibleMeasurementError_old = 0;
  measurement.value.inspectionPointAssessment = "Gut";
  measurement.value.rework = "Nicht erforderlich";
  measurement.value._id = null;
}
async function getMeasurementDefinitionsForCase(){
  console.log("new pont: "+selectedInspectionPoint.value._id);
  const newMeasurementValues = await APIServiceRestDB.getMeasurementDefinitionsForCaseAndPoint(actualCase.value.parameters._id, selectedInspectionPoint.value._id);
  floatsToLocal(newMeasurementValues);
  console.log(newMeasurementValues);
  Object.keys(newMeasurementValues).forEach(key => {
    measurement.value[key] = newMeasurementValues[key];
  });
  if(!newMeasurementValues  || Object.keys(newMeasurementValues).length === 0){
    console.log("set default measurement values");
    setDefaultMeasurementValues();
  }
}

// Funktion zum Befüllen der Dropdowns
async function fetchData() {
  if(!store.state.actualCase){
    return;
  }
  try {
    actualCase.value.parameters = store.state.actualCase;
    const specs = await APIServiceRestDB.getCaseSpecifications(actualCase.value.parameters.objectSpecId);
    actualCase.value.specs =  convertJsonArraysToJs(specs);

    const ret  = await APIServiceRestDB.getWorkpiecesSpecsForCase(actualCase.value.specs._id);
    workpieces.value = convertJsonArraysToJs(ret);
  } catch (error) {
    console.error("Fehler beim Abrufen von Workpieces: ", error);
    workpieces.value = [];
  }
  console.log(workpieces);
  if (workpieces.value && workpieces.value.length > 0) {
    console.log("select first workpiece "+workpieces.value[0]._id);
    workpieceSelect.value.selectedIndex = 1;
    inspectionPointSelect.value.selectedIndex = 0;
    await selectWorkpieceWithId(workpieces.value[0]);
  }
}

watch(() => store.state.actualCase, async () => {
  fetchData();
});

// Hier die Funktion hinzufügen
// eslint-disable-next-line no-unused-vars
/**
 * Handles a measurement change.
 *
 * @param {Object} newMeasurement - The new measurement to handle.
 * @return {Promise<void>} - A promise that resolves when the measurement change is handled.
 */
async function handleMeasurementChange(newMeasurement) {
  const saveMeasurement = {...newMeasurement};
  reformatFloats(saveMeasurement);
  if(!saveMeasurement._id){
    await APIServiceRestDB.saveMeasurementDefinitions(saveMeasurement);
    await getMeasurementDefinitionsForCase();
  }else{
    await APIServiceRestDB.updateMeasurementDefinitions(saveMeasurement);
  }
}

let timeoutId = null;

// Methode zum Verarbeiten der Messwertänderungen mit Verzögerung
/**
 * Sets a delayed timeout for handling a measurement change.
 *
 * @param {any} newMeasurement - The new measurement value.
 * @return {void}
 */
function handleMeasurementChangeDelayed(newMeasurement) {
  clearTimeout(timeoutId); // Zuvor geplanten Timeout löschen

  // Setzen Sie einen neuen Timeout für die Methode
  timeoutId = setTimeout(() => {
    handleMeasurementChange(newMeasurement);
  }, 1000); // Hier können Sie die Verzögerungszeit in Millisekunden anpassen (z.B. 500ms)
}

// Watcher für `measurement` hinzufügen
//watch(measurement, handleMeasurementChangeDelayed);
watch(measurement.value, handleMeasurementChangeDelayed);

onMounted(fetchData);

// Funktion, die beim Ändern des ersten Dropdowns aufgerufen wird
/**
 * Handles the change event of the workpiece dropdown.
 *
 * @param {Event} event - The change event object.
 * @return {void}
 */
async function handleWorkpieceDropdownChange(event) {
  const selectedValue =  workpieces.value.find(item => item._id === event.target.value);
  await selectWorkpieceWithId(selectedValue);
}

async function selectWorkpieceWithId(selectedValue){
  console.log("select workpiece: "+selectedValue);

  if(selectedValue){
    selectedWorkpiece.value = selectedValue;
    drawingImageUrl.value = assetsUrl.value + selectedWorkpiece.value.image;
    inspectionPoints.value = await APIServiceRestDB.getInspectionPointsSpecs(selectedValue._id);

    if(inspectionPoints.value.length > 0){
      console.log("set inspection point");
      setInspectionPoint(inspectionPoints.value[0]._id);

      // Set select element to the option with value '6589b77b8db3c04600000104'
      setTimeout(() => {
        // Der Code in diesem Block wird nach 4 Sekunden ausgeführt.
        inspectionPointSelect.value.value = inspectionPoints.value[0]._id;
      }, 500);
    }

  }else{
    selectedWorkpiece.value = {};
  }
}

async function handleInspectionPointDropdownChange(event){
  const selectedValue = event.target.value;
  setInspectionPoint(selectedValue);
}
// Funktion zum Auswählen des vorherigen InspectionPoints
function selectPreviousInspectionPoint() {
  const currentIndex = inspectionPoints.value.findIndex(item => item._id === selectedInspectionPoint.value._id);
  if (currentIndex > 0) {  // Es gibt ein vorheriges Element
    const prevItem = inspectionPoints.value[currentIndex - 1];
    setInspectionPoint(prevItem._id);
    inspectionPointSelect.value.value = prevItem._id;  // Aktualisiere das Select-Element
  }
}

// Funktion zum Auswählen des nächsten InspectionPoints
function selectNextInspectionPoint() {
  const currentIndex = inspectionPoints.value.findIndex(item => item._id === selectedInspectionPoint.value._id);
  if (currentIndex < inspectionPoints.value.length - 1) { // Es gibt ein nächstes Element
    const nextItem = inspectionPoints.value[currentIndex + 1];
    setInspectionPoint(nextItem._id);
    inspectionPointSelect.value.value = nextItem._id;  // Aktualisiere das Select-Element
  }
}

function handleInspectionPointClick(inspectionPoint) {
  setInspectionPoint(inspectionPoint._id);
  inspectionPointSelect.value.value = inspectionPoint._id;
}

/**
 * Sets the selected inspection point based on the provided id.
 * Updates the suggestion image URL, marker position and visibility.
 * Also sets the title value and retrieves measurement definitions for the case.
 *
 * @param {string} id - The id of the inspection point to set.
 *
 * @return {void}
 */
function setInspectionPoint(id){
  const foundItem = inspectionPoints.value.find(item => item._id === id);
  console.log("set inspection point "+title.value);
  console.log(foundItem);

  if(foundItem) {
    selectedInspectionPoint.value = foundItem; // Speichern des gefundenen Objekts

    suggestionImageUrl.value = assetsUrl.value + selectedInspectionPoint.value.image;
    const element = document.querySelector('.marker-rectangle');
    if(selectedInspectionPoint.value.marker){
      const coords = JSON.parse(selectedInspectionPoint.value.marker);
      if (element) {
        element.style.left = coords[0]+"%";
        element.style.top = coords[1]+"%";
        element.style.width = coords[2]+"%";
        element.style.height = coords[3]+"%";
        element.style.display = "block";
      }
    }else{
      element.style.display = "none";
    }
  } else {
    selectedInspectionPoint.value = {}; // Zurücksetzen, falls kein Objekt gefunden wurde
  }
  title.value = selectedInspectionPoint.value.title;
  //setDefaultMeasurementValues();
  getMeasurementDefinitionsForCase();
}


// eslint-disable-next-line no-unused-vars
function openDrawing() {
  if(selectedWorkpiece.value && selectedWorkpiece.value.drawing) {
    const url = assetsUrl.value + selectedWorkpiece.value.drawing;
    window.open(url);
  }
}

function validateFloat(value) {
  if (value === null) {
    return false;
  }
  if (typeof value !== 'string') {
    value = String(value);
  }
  return /^-?\d*([.,]\d*)?$/.test(value);
}

// Methode zum Verarbeiten von Eingaben
function handleInput(fieldName, event) {
  let inputValue = event.target.value;
  // Überprüfen, ob die Eingabe eine gültige Fließkommazahl ist
  if (!validateFloat(inputValue)) {
    // Die Eingabe ist keine gültige Fließkommazahl, setzen Sie den Wert im measurement-Objekt auf den vorherigen Wert
    measurement.value[fieldName] = measurement.value[fieldName+"_old"]; // Setzen Sie den Wert auf den vorherigen Wert zurück
  }else{
    measurement.value[fieldName+"_old"] = measurement.value[fieldName];
  }
}

</script>

<style scoped>
</style>
