<template>
  <MainLayout />
</template>

<script>
import MainLayout from './layouts/MainLayout.vue';
import LanguageService from "@/services/LanguageService";
export default {
  name: 'App',

  components: {
    MainLayout
  },
  created() {
    const lang = LanguageService.getLanguageFromURL();
    LanguageService.setLanguage(this.$i18n, lang || 'default');
  },

  data: () => ({
    //
  }),
}
</script>
