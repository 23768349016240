<template>
  <v-container>
    <v-row class="text-center">
      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-5">
          {{ $t('Explanation') }}
        </h2>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-5">
          Links
        </h2>

        <v-row justify="center">
          <a
            v-for="(link, i) in importantLinks"
            :key="i"
            :href="link.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ link.text }}
          </a>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'HomeComponent',
  data() {

    return {
      importantLinks: [
        {
          text: 'C-Learning',
          href: 'https://www.c-learning.com',
        },
        {
          text: this.$t('Help'),
          href: 'https://www.c-learning.com/support-messkurs-metalltechnik/',
        },
      ],
    };
  },
}
</script>
