import axios from 'axios'
//import router from '@/router'
//import store from '@/store'


//const serverURL = 'http://localhost:3000'
// const serverURL = 'https://s2.click2meet.de:3000'
const serverURL = 'https://christiani-92b8.restdb.io/rest'

const config = {
    baseURL: `${serverURL}`,
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-apikey': '64dfe213a3ea46c9e9978df0'
    },
    timeout: 10000
}


//let originalUrl = null
let withAuth = false;

const apiClient = axios.create(config)

apiClient.interceptors.request.use(function (config) {
    // Do something before request is sent
    if(withAuth) {
        console.log("in interceptor: "+`Bearer ${localStorage.getItem('wp_api_token')}`);
        config.headers["Authorization"] = `Bearer ${localStorage.getItem('wp_api_token')}`;
    }
    return config;
});

export default {
    /* Sessions */
    async createSession(session) {
        return apiClient.post("/mm-sessions", session).then(result => {
            console.log(result);
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async updateSession(session) {
        return apiClient.put("/mm-sessions/"+session._id, session).then(result => {
            console.log(result);
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async deleteSession(session) {
        return apiClient.delete("/mm-sessions/"+session).then(result => {
            console.log(result);
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async getSession(session) {
        return apiClient.get("/mm-sessions/"+session).then(result => {
            console.log(result);
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async getSessionsForInstructor(inst) {
        console.log(inst);
        return apiClient.get('/mm-sessions?q={"instructor":"'+inst+'"}').then(async result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async getSessionsForUser(user) {
        return apiClient.get('/mm-sessions?q={"user":"'+user+'"}').then(async result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async getObjectWithId(objectId){
        return apiClient.get("/mm-objects/"+objectId).then(result => {
            console.log(result);
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async getObjectsForOwner(owner) {
        return apiClient.get('/mm-objects?q={"owner":"'+owner+'"}').then(async result => {

            for (let object of result.data) {
                object.items = await this.getItemsForObject(object._id);
            }
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async getItemsForObject(object) {
        return apiClient.get('/mm-items?q={"object":"'+object+'"}').then(async result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return [];
        });
    },
    async getSessionsForInstructorAndUser(inst, user) {
        return apiClient.get('/mm-sessions?q={"instructor":"'+inst+'", "user":"'+user+'"}').then(async result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },

    async getMeasurementDefinitionsForCaseAndPoint(caseId, pointId){
        console.log("caseID: "+caseId);
        return apiClient.get('/mm-inspectionpoint-definitions?q={"caseId":"'+caseId+'", "inspectionPointSpecId":"'+pointId+'"}&sort=_changed&dir=-1').then(result => {
            console.log(result.data);
            if(!result.data || result.data.length < 1){
                return {};
            }
            return result.data[0]
        }).catch(error => {
            console.log(error);
            return [];
        });
    },
    async updateMeasurementDefinitions(measurement){
        console.log(measurement);
        return apiClient.put("/mm-inspectionpoint-definitions/"+measurement._id, measurement).then(result => {
            console.log(result);
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async saveMeasurementDefinitions(measurement){
        console.log(measurement);
        return apiClient.post("/mm-inspectionpoint-definitions", measurement).then(result => {
            console.log(result);
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },

    /* specifications */
    async getCaseSpecifications(caseId){
        return apiClient.get('/mm-cases-specifications/'+caseId).then(result => {
            console.log(result.data);
            return result.data
        }).catch(error => {
            console.log(error);
            return [];
        });
    },

    async getWorkpiecesSpecsForCase(caseId) {
        return apiClient.get('/mm-workpieces-specifications?q={"case":"'+caseId+'"}&sort=title&dir=1').then(result => {
            console.log(result.data);
            return result.data
        }).catch(error => {
            console.log(error);
            return [];
        });
    },
    async getInspectionPointsSpecs(workpiece) {
        return apiClient.get('/mm-inspectionpoint-specifications?q={"workpiece":"'+workpiece+'"}&sort=number&dir=1').then(result => {
            console.log(result.data);
            return result.data
        }).catch(error => {
            console.log(error);
            return [];
        });
    },
}
