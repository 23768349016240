// store.js
import Vuex from 'vuex';


export default new Vuex.Store({
    state: {
        caseId: null,
        actualCase: null
    },
    mutations: {
        setCaseId(state, payload) {
            state.caseId= payload;
        },
        setCase(state, payload){
            state.actualCase = payload;
        }
    },
    actions: {
        updateCaseId({ commit }, payload) {
            commit('setCaseId', payload);
        },
        updateCase({ commit }, payload) {
            commit('setCase', payload);
        }
    }
});
