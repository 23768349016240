export default {
    setLanguage(i18n, lang) {
        if (i18n.availableLocales.includes(lang)) {
            i18n.locale = lang;
            // Hier kannst du auch den URL-Parameter aktualisieren
        }
    },
    getLanguageFromURL() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('lang');
    },
};
