<template>
  <v-card>
    <v-row>
      <v-col class="d-flex align-center">
        <v-card-title>{{ $t(pageTitle) }}</v-card-title>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex align-center justify-end">
        <div>{{ caseTitle }}</div>
        <v-spacer></v-spacer>
        <LanguageSwitcher></LanguageSwitcher>
        <v-img
            :src="require('../assets/christiani-logo-klein.png')"
            class="my-3"
            contain
            height="40"
        />
        <router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import {useRoute } from "vue-router";
import { useStore } from 'vuex';
import {ref, watch} from "vue";
import APIServiceRestDB from "@/services/APIServiceRestDB";

export default {
  data() {
    return {

    }
  },
  components: {
    LanguageSwitcher
  },
  setup() {
    const store = useStore(); // Verwende useStore, um auf den Vuex Store zuzugreifen
    const pageTitle = ref('Messkurs Metalltechnik');
    const caseTitle = ref("-");
    const route = useRoute();

    watch(() => route.name, (newRouteName) => {
      pageTitle.value = newRouteName; // Wenn es sich um einen einfachen Text handelt und nicht um einen Übersetzungsschlüssel
    });

    watch(() => store.state.caseId, async (newCaseId) => {
      console.log("changed to " + newCaseId);
      try {
        const object = await APIServiceRestDB.getObjectWithId(newCaseId);
        console.log(object);
        if(object){
          caseTitle.value = object.title;
        }
      } catch (error) {
        console.error("Error fetching case:", error);
        // Behandle Fehler, z.B. setzen eines Fehlerstatus oder einer Fehlermeldung
      }
    });

    return { pageTitle, caseTitle };
  },
  methods:{
    changePageTitle(){
      console.log("test");
    }
  },
  async mounted() {
  },

}
</script>

<style scoped>

</style>
