<template>
  <div class="locale-changer">
    <v-icon style="margin-right: 5px;color: #03164e;">mdi-earth</v-icon>
    <select v-model="$i18n.locale">
      <option value="de">
        <v-icon class="mdi mdi-flag-de"></v-icon> Deutsch
      </option>
      <option value="en">
        <v-icon class="mdi mdi-flag-gb"></v-icon> Englisch
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data () {
    return { langs: ['de', 'en'] }
  }
}
</script>
