<template>
  <Header />
  <router-view></router-view>
  <!--Footer /-->
</template>

<script>
import Header from '../views/HeaderView.vue';
//import Footer from './Footer.vue';
import {useRoute } from "vue-router";
import { useStore } from 'vuex';
import {onMounted, watch, ref} from "vue";
import APIServiceRestDB from "@/services/APIServiceRestDB";

export default {
  components: {
    Header,
    //Footer
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const caseTitle = ref("-");

    // Funktion zum Erfassen und Aktualisieren des Case-Parameters
    const captureCaseParameter = async () => {
      const caseId = route.query.case;
      if (caseId) {
        // Annahme: Die Aktion 'updateCaseId' ist in deinem Vuex Store definiert
        store.dispatch('updateCaseId', caseId);
        const caseTmp = await APIServiceRestDB.getObjectWithId(caseId);
        if(caseTmp){
          store.dispatch('updateCase', caseTmp);
        }
      }
    };

    // Beobachte Änderungen am Query-Parameter
    watch(() => route.query.case, captureCaseParameter);

    // Überprüfe den Parameter, wenn die Komponente gemountet wird
    onMounted(captureCaseParameter);

    return { caseTitle };
  }
};
</script>
