import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import AboutView from "@/views/AboutView.vue";
import SettingsView from "@/views/SettingsView.vue";
import InspectionPointView from "@/views/InspectionPointView.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/settings",
        name: "Settings",
        component: SettingsView,
    },
    {
        path: "/lernen",
        name: "Lernen",
        component: InspectionPointView,
    },
    {
        path: "/ueben",
        name: "Ueben",
        component: InspectionPointView,
    },
    {
        path: "/test",
        name: "Test",
        component: InspectionPointView,
    },
    {
        path: "/inspectionpoints",
        name: "Inspection Points",
        component: InspectionPointView,
    },
    {
        path: "/about",
        name: "About",
        component: AboutView,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
