// i18n.js
import { createI18n } from 'vue-i18n';
import en from '../../../../S3MGPT/src/locales/en.json';
import de from '../../../../S3MGPT/src/locales/de.json';

const messages = {
  en,
  de
};

// Ermitteln der Browsersprache
const browserLanguage = navigator.language.split('-')[0];

const i18n = createI18n({
  locale: browserLanguage || 'en', // Verwenden Sie die Browsersprache oder Standard auf Englisch
  fallbackLocale: 'en', // Ersatzsprache
  messages,
});

export default i18n;
